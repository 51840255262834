<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="_managers"
      :search="search"
      :items-per-page="10"
      class="br-16 elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center br-16 pa-4">
          <v-text-field
            v-model="search"
            class="br-8"
            placeholder="Filter managers"
            label="Title"
            style="max-width: 350px"
            prepend-inner-icon="search"
            hide-details
            outlined
            dense
          />
          <v-btn
            color="yellow"
            rounded
            @click="add_new_manager = true"
            class="mr-0"
          >
            <v-icon color="black" class="mr-2">mdi-plus</v-icon>Add new
            user</v-btn
          >
        </div>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <router-link
          class="no-text-decoration"
          :to="{
            name: 'admin-brands-id-new-news',
            params: { id: selected_brand.id },
            query: { id: item.id },
          }"
        >
          <v-icon>mdi-pencil</v-icon>
        </router-link>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          icon
          @click="(selected_user = item), (remove_user_dialog = true)"
        >
          <v-icon color="red darken-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <AdminDialog
      v-if="remove_user_dialog"
      :show_dialog.sync="remove_user_dialog"
      header="Are you sure?"
      @confirm="deactivate_user(selected_user)"
      :loading="is_deleting"
      confirm_text="Delete"
      confirm_color="red"
    >
      <template v-slot:[`content`]>
        Do you want to delete selected user?
      </template>
    </AdminDialog>
    <AdminDialog
      v-if="add_new_manager"
      :show_dialog.sync="add_new_manager"
      header="Create New User"
      @confirm="
        create_brand_manager({ name: new_admin_name, email: new_admin_email })
      "
      :loading="is_creating_admin"
      :disabled="!is_new_manager_valid"
      confirm_text="Confirm"
      confirm_color="primary"
    >
      <template v-slot:[`content`]>
        <v-alert type="info" border="left" colored-border>
          Please note, the new user will receive an email with further
          instructions on how to create their new password and log in to their
          dashboard.
        </v-alert>
        <v-form v-model="is_new_manager_valid">
          <v-text-field
            type="text"
            v-model="new_admin_name"
            label="Name"
            class="required"
            required
            :rules="[field_req]"
          />
          <v-text-field
            type="text"
            v-model="new_admin_email"
            label="Email"
            class="required"
            required
            :rules="[field_req, valid_email]"
          />
        </v-form>
      </template>
    </AdminDialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { field_req, valid_email } from "@/utils/form_val_rules";

import {
  get_brand_managers,
  create_brand_manager,
  deactivate_brand_manager,
} from "@/requests";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "BrandNews",
  components: {
    AdminDialog,
  },
  data() {
    return {
      moment,
      field_req,
      valid_email,
      loading_id: null,
      new_admin_name: null,
      new_admin_email: null,
      is_deleting: false,
      is_creating_admin: false,
      is_new_manager_valid: false,
      selected_user: null,
      remove_user_dialog: false,
      add_new_manager: false,
      managers: [],
      search: null,
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Delete", value: "delete", align: "center", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.get_brand_managers();
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    _managers() {
      if (this.search) {
        return this.managers.filter((p) => p.name.includes(this.search));
      }
      return this.managers;
    },
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brand_by_id"]),
    async get_brand_managers() {
      const { data: managers } = await get_brand_managers(
        this.selected_brand.id
      );
      this.managers = managers;
    },
    async create_brand_manager(new_brand_manager) {
      try {
        this.is_creating_admin = true;
        await create_brand_manager({
          brand_id: this.selected_brand.id,
          ...new_brand_manager,
        });
        await this.get_brand_managers();
        this.add_new_manager = false;
        this.new_admin_name = null;
        this.new_admin_email = null;
      } finally {
        this.is_creating_admin = false;
      }
    },
    async deactivate_user(user) {
      await deactivate_brand_manager(user.id);
      await this.get_brand_managers();
      this.remove_user_dialog = false;
      this.selected_user = null;
    },
  },
};
</script>
